import type {Webmessage, webmessageResponse} from '../../types/backendtypes'
import { defineStore } from 'pinia'
interface webmessageStore {
    webmessages: Webmessage[]
}

export const useWebmessageStore = defineStore('webmessages', {
    state: (): webmessageStore => ({
        webmessages: [
            // {"wsm_newsletterHeadline": "Sign up for our newsletter!"},
            // {"wsm_signup": "Get the newest updates and offers!"},
            //{'wsm_footerCompanyInfo': '© Pharma Nord • Box 8134 • S-163 08 Spånga •  <a href="http://www.pharmanord.se" target="_blank" rel="noopener noreferrer">www.pharmanord.se</a>'},
        ]
    }),
    getters: {
        getWebmessage: (state) => {
            return (webMsg) => state.webmessages.find((wsm) => webMsg in wsm)
        },
        getWebmessageTest: () => (wsm: string) => useWebmessageStore().wsmRetriever(wsm),
    },
    actions: {
        addWebmessagesToStoreServerSide(wsm: Webmessage[]){

            if(wsm != null || wsm != undefined){
                wsm.forEach( msg => {
                    const doesWsmExist = this.doesWebMessageExist(msg.key)
                    if(!doesWsmExist){
                        this.webmessages.push(this.transformWSM(msg));
                    }else{
                        console.log("Webmessage already exists in store", msg)
                    }
                })
            }
        },
        async wsmRetriever(wsm: string): Promise<Webmessage | undefined> {
            const checkIfwsmExist = this.doesWebMessageExist(wsm); //check if wsm is in store
            if(checkIfwsmExist) {
                return this.webmessages.find((x: Webmessage) => Object.keys(x)[0] == wsm) // found wsm. returning wsm object
            } else {
                // if webmessage is not found, request it.
                // const {data} = await useAsyncData<webmessageResponse>(wsm, () => $fetch( '/webmessages', {

                const data = await $fetch<webmessageResponse>( '/api/webmessages', {
                    method: 'POST',
                    body: [wsm],
                    headers: useRequestHeaders()
                })
                

                //if webmessage is found
                if(data) {
                    const transformedWsm = this.transformWSM(data.wsm_trls[0])
                    
                    this.webmessages.push(transformedWsm); // put it in the store
                    return transformedWsm; // and return it

                } else {
                    console.error('Webmessage not found');
                }
            }
        },
        transformWSM(wsmObject: Webmessage): Webmessage {
            //transform webmessage from {key: 'wsm_something', value: 'something'} to {'wsm_something': 'something'}
                return {[wsmObject.key]: wsmObject.value}
            
        },
        doesWebMessageExist(wsm: string) {
            return this.webmessages.map(x => {
                return Object.keys(x)[0]
            }).includes(wsm); // does webmessage array include the web message requested
        }
    },
})