export interface User {
    id: number,
    name: string,
    role: role
}

interface userStor {
    user: User
}

export enum role {
    admin = "admin",
    customer = "customer",
    localadmin = "localadmin"
}

export const useUserStore = defineStore('user', {
    state: (): userStor => ({
        user: {} as User,
    }),
    getters: {
        getUser: (state): User => state.user,
        isAdmin: (state): Boolean => (state.user.role == role.admin)
    },
    actions: {
        setUser(user: User) {
            this.user = user;
        },

        loginUser(username: string, password: string) {
            console.log('call login endpoint')
            // hardcoded
            this.setUser({
                id: 1,
                name: username,
                role: role.admin
            })

            //useRouter().push('/')
        },
        logUserOut() {
            this.user = {} as User
        }
    },
})