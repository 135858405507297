import { useRouter } from 'nuxt/app';
import type { MenuItem} from '@/types/backendtypes'

export const addRoutesOnEnter = (menus: MenuItem[]) => {
    const router = useRouter();
    // Because your composable is called in the right place in the lifecycle,
    // useRuntimeConfig will also work
    const apiroutes = () => useState('routes')

    if (!apiroutes?.length) {
        useState('routes', () => menus)
        menus.forEach((link: MenuItem) => {
            router.addRoute({
                path: link.path,
                name: link.name,
                component: () => import("@/pages/[...slug].vue")
            })
        });
    }
}