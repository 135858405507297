import type { PageProperties } from "~/types/backendtypes"
export default defineNuxtPlugin(() => {
    return {
      provide: {
        getPageProperty: (keyname: string, pageProperties: PageProperties[] | undefined): PageProperties | undefined | null => {
            if(pageProperties) {
                return pageProperties.find(x => x.name == keyname)
            } else {
                return null
            }
            
        }
      }
    }
  })