<template>
    <div class="bg h-full w-full flex justify-center">
        <NuxtLayout class="sm:min-h-0 !bg-right-top  !bg-no-repeat" :name="'evelle2011content'">
            <div class="w-full h-full flex flex-col items-center justify-center">
                <div
                    class="md:w-full w-1/2 p-8 flex justify-center items-center flex-col backdrop-blur-3xl rounded-lg shadow-lg">

                    <div class="flex justify-center w-full">
                        <div class="flex justify-center flex-col items-center">
                            <div>
                                <p class="max-w-prose mt-2 text-2xl text-center text-evellePrimary font-[Prata]">
                                    <span class="font-bold">{{props.error?.statusCode}} {{ props.error?.statusMessage }}</span>
                                </p>
                                <p class="max-w-prose mt-2 text-1xl text-center text-textColor font-[Prata]">
                                    Please try to go to our frontpage or come back at a later time
                                </p>
                            </div>

                            <button @click="handleError()"
                                class="mt-8 py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-white bg-evellePrimary focus:outline-none  rounded-lg border-solid border-2 border-evellePrimary hover:bg-evellePrimaryHover hover:border-evellePrimaryHover focus:z-10 focus:ring-4 focus:ring-gray-200">
                                Go to frontpage
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </NuxtLayout>
    </div>
</template>

<script setup lang="ts">

const props = defineProps({
    error: Object as any | undefined
})

const handleError = (): void => {
    clearError({})
    window.location.href = "/"
}

const router = useRouter();

const removeNavigationGuard = router.beforeEach(to => {
  removeNavigationGuard();
  clearError({});
  navigateTo(to.fullPath, {external: true})
});
</script>

<style scoped></style>