import type {Webmessage} from '~/types/backendtypes'

export default defineNuxtPlugin((nuxtApp) => {
  return {
    provide: {
      getWebmessages: async (arrayOfWsmKeys: string[]): Promise<Webmessage[] | null> => {
          const { data } = await useFetch<Webmessage[]>('/api/webmessages', {
            method: 'POST',
            body: arrayOfWsmKeys,
            headers: useRequestHeaders()
          })
          if(data.value) {
            return data.value
          } else {
            throw createError({
              statusCode: 404,
              statusMessage: 'Webmessages not found'
            })
          }
      },
      
      getWebmessage: (wsmkey: string, translations: Webmessage[]): Webmessage | undefined => {
        if(translations && translations.find(x => x[wsmkey])) {
          return translations.find(x => x[wsmkey]);
        } else {
          return {}
        }
      }
    },
  };
});