<template>
    <div class="w-2/3 teaser grid-cols-2 grid mx-4 sm:mx-0 rounded-lg shadow-lg overflow-hidden md:grid-cols-1">

        <div :class="[imgOrder]">
            <img class="rounded-tl-lg h-full object-cover" width="430" height="436" alt="woman1"
                src="/woman1.webp">
        </div>
        <div class="bg-primary p-8 text-sm" :class="[contentOrder]">
            <div v-html="content"></div>
       
        </div>

    </div>
</template>

<script lang="ts">
export default defineNuxtComponent({
    name: 'componentContentImgElement',
    props: {
        content: {
            type: String,
            default: '<p class="font-[Prata] mb-2 text-xl">Skönhet i en tablett</p><p>För att behålla din hud, ditt hår och dina naglar friska och ungdomliga, måste du ge dem den bästa vården, utifrån så väl som inifrån. Evelle är ett exklusivt kosttillskott med syfte att hjälpa dig att behålla din naturliga skönhet inifrån. Evelle innehåller ett urval av växtextrakt och ingredienser, noggrant utvalda utifrån dess väl dokumenterade biologiska egenskaper.</p>'
        },
        contentDirection: {
            type: String,
            default: ""
        }
    },
    data(){
        return {
            imgOrder: "",
            contentOrder: "", 
        }
    },
    mounted(){
        this.layoutDirection;
    },
    computed:{
        layoutDirection(){
            if(this.contentDirection == "reverse"){
                this.imgOrder = "order-1",
                this.contentOrder = "order-first"
            }else{
                
            }
        }
    },
    setup(props) {
        const url = useRuntimeConfig().public.API_BASE_AND_PATH
        const content = props.content
        return {content, url}
    }
})
</script>