<template>
  <div class="bg w-full h-full flex min-h-full flex-col items-center">

  
    <NuxtLayout :name="entryTemplate">

            <NuxtPage></NuxtPage>  
      </NuxtLayout>
      
  </div>
</template>

<script lang="ts">
import { useUserStore } from '@/stores/user/userStore';
import { useAPI } from './composables/useApi';

export default defineNuxtComponent({
  name: 'app',
  async setup() {
    const user = useUserStore()

    useHead({
      link: [{
        rel: "icon", 
        type: "image/png", 
        href: "/favicon.png"
      }],
      htmlAttrs: {
        lang: 'se'
      }
    })
    const api = useAPI()

    const websiteData = await api.getWebsiteData(); 
    
    const websiteMenu = await api.getMenu();
    

    //// LAYOUT
      const { $pageLayout } = useNuxtApp();
      const entryTemplate = await $pageLayout(websiteData.entryTemplate);

    //// CREATE MENU ROUTES
      if(websiteMenu.menus){
        const menuFrontpageChangePath = ref(websiteMenu)
        menuFrontpageChangePath.value.menus[0].path = "/"
        addRoutesOnEnter(menuFrontpageChangePath.value.menus)
      }
      useState('isNavigationOpen', ()=> false);
    
    ////

    
    //force login
    const isAdmin = false
    

    return { entryTemplate, isAdmin }
  }
})
</script>

<style lang="scss">
.bg {
  background: rgb(255, 255, 255);
  background: radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 35%, rgba(231, 217, 152, 1) 100%);
}

h1 {
  @apply font-bold text-4xl text-evellePrimary;
}
h2 {
  @apply font-bold text-3xl text-evellePrimary;
}
p, ul {
  @apply text-textcolor;
}
p a{
  @apply text-evellePrimary;
}
ul{
  @apply list-disc pl-4;
}
img {
  width: 100%;
}

.page-enter-active,
.page-leave-active {
  transition: all 0.15s;
  color: theme('colors.linkColor')
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(0.2rem);
}

::-webkit-scrollbar {
  width: 10px;
}
/* Track */
*::-webkit-scrollbar-track {
  background-color: #acacac !important;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background-color: #eee3b3;
  border-radius: 25px;
}

/* Handle on hover */
div::-webkit-scrollbar-thumb:hover {
  background: #ddcf90;
}

// Animation
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>