import type { ApiResponse, Page, WebsiteData, Menu } from "~/types/backendtypes";
import { useWebmessageStore } from "~/stores/webmessages/webmessageStore";

async function apiCall(path: string): Promise<any> {
    const {data} = await useAsyncData(path.toString(), () => $fetch(path, {
        headers: useRequestHeaders()
    }))
    return data.value
}

export const useAPI = () => {
    return {
        getWebsiteData: async (): Promise<WebsiteData> => {
            const data = await apiCall('/api/websitedata');
       
            if(data.statusCode == 404){
                const error = data.error.value; 
                console.log("useApi Websitedata Error: ", error);
                
                const customMessages = error?.data?.data?.customStatusMessage || ''
                throw createError({
                    statusCode: error.statusCode,
                    statusMessage: error.statusMessage,
                    data: {
                        customStatusMessage: customMessages
                    },
                    //fatal: true,
                })
            }else{
                return data;
            }
        },
        getUser: async (): Promise<string> => {
            const data = await apiCall('/api/getuser');
            if(data.statusCode == 404){
                const error = data.error.value; 
                console.log("useApi getuser Error: ", error);
                
                const customMessages = error?.data?.data?.customStatusMessage || ''
                throw createError({
                    statusCode: error.statusCode,
                    statusMessage: error.statusMessage,
                    data: {
                        customStatusMessage: customMessages
                    },
                    //fatal: true,
                })
            }else{
                return data;
            }
        },
        getMenu: async (): Promise<Menu> => {
            const data = await apiCall('/api/menus'); 
            if(data.statusCode == 404){
                const error = data.error.value;

                console.log("useApi Menu Error: ", error);
                const customMessages = error?.data?.data?.customStatusMessage || ''
                throw createError({
                    statusCode: error.statusCode,
                    statusMessage: error.statusMessage,
                    data: {
                        customStatusMessage: customMessages
                    },
                    //fatal: true,
                })
            }else{
                return data
            }

        },
        getPage: async (url: String): Promise<Page> => {
            try {
                const data = await apiCall('/api/page?slug='+url);
                useWebmessageStore().addWebmessagesToStoreServerSide(data.webMessages);
                return data;
            } catch(err) {
                throw createError({
                    status: err.statusCode,
                    statusText: err.statusMessage,
                    fatal: true,
                })
            }
        }

    }
  }