import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAdminButton, LazyAdminEditWrapper, LazyAdminSideBar, LazyAdminTextEditor, LazyAdminWebmessageEdit, LazyComponentBurgerButton, LazyComponentContentImgElement, LazyComponentEmailSignUp, LazyComponentFooter, LazyComponentHeader, LazyComponentIconWithText, LazyComponentModal, LazyComponentNewsletterSignup, LazyComponentNewsletterSignupMini, LazyContact, LazyErrorBoundaryCustom, LazyErrorComponent, LazyNavBar, LazySimplecontentelementhtml4, LazyWebmessage } from '#components'
const lazyGlobalComponents = [
  ["AdminButton", LazyAdminButton],
["AdminEditWrapper", LazyAdminEditWrapper],
["AdminSideBar", LazyAdminSideBar],
["AdminTextEditor", LazyAdminTextEditor],
["AdminWebmessageEdit", LazyAdminWebmessageEdit],
["ComponentBurgerButton", LazyComponentBurgerButton],
["ComponentContentImgElement", LazyComponentContentImgElement],
["ComponentEmailSignUp", LazyComponentEmailSignUp],
["ComponentFooter", LazyComponentFooter],
["ComponentHeader", LazyComponentHeader],
["ComponentIconWithText", LazyComponentIconWithText],
["ComponentModal", LazyComponentModal],
["ComponentNewsletterSignup", LazyComponentNewsletterSignup],
["ComponentNewsletterSignupMini", LazyComponentNewsletterSignupMini],
["Contact", LazyContact],
["ErrorBoundaryCustom", LazyErrorBoundaryCustom],
["ErrorComponent", LazyErrorComponent],
["NavBar", LazyNavBar],
["Simplecontentelementhtml4", LazySimplecontentelementhtml4],
["Webmessage", LazyWebmessage],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
