// For Nuxt 3
import { library, config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {faEdit, faLanguage,faFloppyDisk, faBan } from '@fortawesome/pro-solid-svg-icons'
import {faEyeSlash,faRotateRight,faRotate, faObjectUnion, faEdit as falEdit, faBars } from '@fortawesome/pro-light-svg-icons'
// This is important, we are going to let Nuxt worry about the CSS
config.autoAddCss = false

// You can add your icons directly in this plugin. See other examples for how you
// can add other styles or just individual icons.
library.add(faEdit, falEdit, faLanguage,faFloppyDisk, faBan, faEyeSlash,faRotateRight,faRotate, faObjectUnion, faEdit, faBars)
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('font-awesome-icon', FontAwesomeIcon, {})
})