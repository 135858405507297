import { default as _91_46_46_46slug_93k23u9WL22NMeta } from "/home/evelle-2023/pages/[...slug].vue?macro=true";
import { default as login24cOOza8jHAMeta } from "/home/evelle-2023/pages/login2.vue?macro=true";
import { default as rwjgnonoiwgronwrggxCDDiHEgqtMeta } from "/home/evelle-2023/pages/rwjgnonoiwgronwrgg.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/home/evelle-2023/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "login2",
    path: "/login2",
    component: () => import("/home/evelle-2023/pages/login2.vue").then(m => m.default || m)
  },
  {
    name: "rwjgnonoiwgronwrgg",
    path: "/rwjgnonoiwgronwrgg",
    component: () => import("/home/evelle-2023/pages/rwjgnonoiwgronwrgg.vue").then(m => m.default || m)
  }
]